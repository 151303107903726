<template>
  <div
    class="px-2 py-4 w-full bg-blue-100 text-center fixed z-20"
    v-if="user && user.proxied"
  >
    <div class="text-blue-600 font-poppins font-medium">
      You are viewing the site as {{ user.firstName }} {{ user.lastName }}.
    </div>
    <div class="absolute right-4 top-1/2 transform -translate-y-1/2">
      <span class="text-blue-600 font-poppins font-medium mr-4"
        >Return to admin view</span
      ><Button variant="blue" outline @click="stopProxy">Exit</Button>
    </div>
  </div>
  <div
    class="relative h-full"
    :style="[user && user.proxied ? { top: '56px' } : { top: '0px' }]"
  >
    <div class="flex h-full">
      <aside class="fixed h-full bg-gray-700 lg:block">
        <div class="px-8 mt-8">
          <img src="@/assets/logos/csm_white.svg" />
          <p class="mt-2 font-normal text-yellow-500">Invoicing</p>
        </div>
        <nav>
          <router-link to="/dashboard">
            <dashboard-icon class="inline-block w-6 h-6 text-white" />
            <span>Dashboard</span>
          </router-link>
          <router-link to="/invoices-by-jobs">
            <folders-icon class="inline-block w-6 h-6 text-white" />
            <span>Invoices by Jobs</span>
          </router-link>
          <router-link
            to="/notifications"
            v-if="allow('notifications', 'view')"
          >
            <notifications-icon class="inline-block w-6 h-6 text-white" />
            <span>Notifications</span>
          </router-link>
        </nav>
        <div class="mx-10 my-8 border-b"></div>
        <nav>
          <router-link to="/account">
            <account-icon class="inline-block w-6 h-6 text-white" />
            <span>My Account</span>
          </router-link>
          <router-link to="/presets" v-if="allow('presets', 'view')">
            <presets-icon class="inline-block w-6 h-6 text-white" />
            <span>Presets</span>
          </router-link>
          <router-link to="/users" v-if="allow('users', '*')">
            <users-icon class="inline-block w-6 h-6 text-white" />
            <span>Users</span>
          </router-link>
          <router-link
            to="/role-management"
            v-if="allow('permissions', 'edit')"
          >
            <permissions-icon class="inline-block w-6 h-6 text-white" />
            <span>Role Management</span>
          </router-link>
          <router-link to="/auth-tokens" v-if="allow('auth:tokens', '*')">
            <key-icon class="inline-block w-6 h-6 text-white" />
            <span>API Tokens</span>
          </router-link>
        </nav>
      </aside>
      <div class="flex-1 overflow-x-hidden" :style="{ marginLeft: '252px' }">
        <header
          class="relative flex items-center justify-end p-4 bg-white shadow z-10"
        >
          <p class="mr-16 text-sm text-gray-600 font-poppins">
            You are logged in as<br /><span
              class="text-xl font-bold font-poppins"
              >{{ fullName }}</span
            >
          </p>
          <router-link
            to="/notifications"
            v-if="allow('notifications', 'view')"
          >
            <div class="relative">
              <notifications-icon
                class="inline-block w-6 h-6 text-gray-400 hover:text-yellow-500 transition-all duration-300 ease-out"
              />
              <span class="badge absolute -right-2">{{
                notifications.length
              }}</span>
            </div>
          </router-link>
          <button class="ml-8" @click="openLogoutModal">
            <logout-icon
              class="inline-block w-6 h-6 text-gray-400 hover:text-yellow-500 transition-all duration-300 ease-out"
            />
          </button>
        </header>
        <main class="block h-full min-h-full relative">
          <router-view />
        </main>
      </div>
      <ConfirmModal id="logout" title="Logout of Invoicing Tool" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useModal, useAllow, useToast } from '@/helpers/composables';

export default {
  setup() {
    const store = useStore();
    const { open } = useModal('logout');
    const user = computed(() => store.state.user.user);
    const { notifications } = store.state.notifications;
    const fullName = computed(() => store.getters['user/fullName']);
    const toast = useToast();
    const router = useRouter();

    function openLogoutModal() {
      open({
        body: 'You are logging out. Are you sure?',
        confirm: {
          text: 'Yes, Logout',
          action: () => store.dispatch('user/logout'),
        },
      });
    }

    function allow(resource, action) {
      const { permissions } = store.state.user;
      const permission = useAllow(permissions, resource, action);
      return permission;
    }

    async function stopProxy() {
      const { message } = await store.dispatch('user/proxy', {
        method: 'delete',
      });
      toast.show(message);
      router.push({ name: 'dashboard' });
    }

    return {
      openLogoutModal,
      user,
      fullName,
      notifications,
      allow,
      stopProxy,
    };
  },
};
</script>

<style lang="postcss" scoped>
nav {
  @apply mt-10 px-2 space-y-2;
}
nav a {
  @apply block text-white pl-8 py-2 rounded transition-all duration-300 ease-out;
}
nav a img {
  @apply inline-block;
}
nav a span {
  @apply inline-block ml-8;
}
nav a:hover,
nav a.router-link-active,
nav a.router-link-exact-active {
  @apply bg-gray-900;
}
.badge {
  @apply bg-red-500 text-white w-4 h-4 inline-block text-center align-middle leading-4 text-xs font-poppins font-semibold;
  border-radius: 50%;
}
</style>
